import React from 'react';
import Logo from '../../../imgs/logo.svg';
import { Nav } from './styles';
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

function Navbar() {
  return (
    <Nav>
      <div className="container-full">
        <div className="content">
          <div className="header">
            <img src={Logo} alt="Move brands" />
          </div>

          <div className="info">
            <span className="email">hello.movebrands@gmail.com</span>

            <a
              href="https://www.facebook.com/movebrands"
              target="_blank"
              className="link-page link-page-01"
            >
              <i className="fa fa-facebook-square" />
              /movebrands
            </a>

            <a
              href="https://www.instagram.com/move.brands/"
              target="_blank"
              className="link-page"
            >
              <i className="fa fa-instagram" />
              /move.brands
            </a>
          </div>
        </div>
      </div>
    </Nav>
  );
}

export default Navbar;
