import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    height: calc(100vh - 110px);
    align-items: center;
  }

  .content {
    position: relative;
  }

  .lang {
    display: block;
    z-index: 99999;
    margin-bottom: 30px;

    @media (min-width: 1024px) {
      margin-bottom: 0;
      position: absolute;
      left: 50%;
      top: 0;
    }

    button {
      width: 44px;
      height: 27px;
      border-radius: 13.5px;
      background-color: transparent;
      border: 0;
      color: #fff;
      font-weight: 600;
      font-size: 13px;
      border: 1px solid #fff;
      cursor: pointer;
      transition: 0.3s all ease-in-out;
      margin-right: 10px;

      &.active,
      &:hover {
        transition: 0.3s all ease-in-out;
        background-color: #343434;
        border-color: #343434;
      }

      &.active {
        cursor: auto;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .slick-dots {
    display: flex !important;
    flex-direction: column;
    bottom: 40%;
    right: 0;
    width: auto;

    @media (min-width: 1024px) {
      right: -55px;
      bottom: 33%;
    }

    li {
      button {
        &:before {
          font-size: 10px;
          color: #fff;
          opacity: 1;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: #000;
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const List = styled.div`
  margin: 0;
  padding: 0;

  .content-slider {
    width: 100%;
    display: flex !important;
    align-self: flex-start;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
    }

    h1 {
      color: #fff;
      font-weight: bold;
      width: 100%;
      font-size: 40px;

      @media (min-width: 1024px) {
        word-break: break-word;
        line-height: 0.9;
        width: 50%;
        font-size: 91px;
      }

      @media (min-width: 1366px) {
        font-size: 115px;
      }
    }

    p {
      width: 90%;
      color: #fff;
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;

      @media (min-width: 1024px) {
        width: 50%;
        font-size: 15px;
        line-height: 26px;
        margin-top: 50px;
        max-width: 510px;
      }

      @media (min-width: 1366px) {
        font-size: 20px;
        line-height: 34px;
      }
    }
  }
`;
