import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;

  margin: 0 0 40px;
  @media (min-width: 1024px) {
    margin: 0;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
      justify-content: unset;
    }

    span {
      color: #fff;
      margin-left: 30px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .email {
    color: #fff;
    font-size: 15px;
    font-weight: bold;

    @media (min-width: 1366px) {
      font-size: 16px;
    }
  }

  .link-page {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s all ease-in-out;

    &-01 {
      @media (max-width: 1024px) {
        margin: 8px 0;
      }
    }

    &:hover {
      color: #343434;
      transition: 0.3s all ease-in-out;
    }

    i {
      margin-right: 5px;
      font-size: 16px;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
      margin: 0;
      width: 50%;
      max-width: 610px;
    }
  }
`;
