/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import Slider from 'react-slick';
import { FormattedHTMLMessage } from 'react-intl';
import { Container, List } from './styles';

/* eslint-disable react/jsx-props-no-spreading */

const settings = {
  dots: true,
  arrows: false,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  speed: 900,
  autoplaySpeed: 9000,
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
};

const mockSlider = ['slider01', 'slider02', 'slider03', 'slider04'];

function BoxSlider() {
  function handleChangeLang(lang) {
    localStorage.setItem('language', lang);
    window.location.reload();
  }

  return (
    <Container>
      <div className="container">
        <div className="content">
          <div className="lang">
            <button
              className={
                localStorage.getItem('language') === 'pt' ? 'active' : ''
              }
              type="button"
              onClick={() => handleChangeLang('pt')}
            >
              BR
            </button>
            <button
              className={
                localStorage.getItem('language') === 'en' ? 'active' : ''
              }
              type="button"
              onClick={() => handleChangeLang('en')}
            >
              EN
            </button>
            <button
              className={
                localStorage.getItem('language') === 'es' ? 'active' : ''
              }
              type="button"
              onClick={() => handleChangeLang('es')}
            >
              ES
            </button>
          </div>

          <List>
            <Slider {...settings}>
              {mockSlider.map((item, index) => (
                <div className="content-slider">
                  <h1>
                    <FormattedHTMLMessage id={`slider.title.${index}`} />
                  </h1>
                  <p>
                    <FormattedHTMLMessage id={`slider.text.${index}`} />
                  </p>
                </div>
              ))}
            </Slider>
          </List>
        </div>
      </div>
    </Container>
  );
}

export default BoxSlider;
