import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import localeEn from 'react-intl/locale-data/en';
import localePt from 'react-intl/locale-data/pt';
import localeEs from 'react-intl/locale-data/es';
import App from './App';

addLocaleData([...localeEn, ...localeEs, ...localePt]);

ReactDOM.render(<App />, document.getElementById('root'));
