import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'font-awesome/css/font-awesome.css';

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,800&display=swap');
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    line-height: 1.2;
    font-family: 'Open Sans', sans-serif;
  }

  html, body, #root {
    min-height: 100%;
    margin: 0;
  }

  body {
    background: #07D86F;
    margin: 0;
    -webkit-font-smoothing: antialiased !important;
    padding: 30px 0;

    @media (min-width: 1024px) {
      padding: 85px 0 0;
    }
  }

  .container {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 20px;

    @media (min-width: 1024px) {
      width: 1024px;
    }

    @media (min-width: 1366px) {
      padding: 0;
      width: 1366px;
    }
  }
  .container-full {
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;

    @media (min-width: 1024px) {
      padding: 0 45px;
    }
  }
`;
