import React from 'react';

import Content from '../Components/content';
import NavBar from '../Components/navbar';

function Home() {
  return (
    <>
      <NavBar />
      <Content />
    </>
  );
}

export default Home;
