import { BrowserRouter, Route, Switch } from 'react-router-dom';

import React from 'react';
import Home from './pages/Home';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        {/* <Route path="*" component={NotFound404} /> */}
      </Switch>
    </BrowserRouter>
  );
}
